
  import {
    sync_scaler_tax_rate,
    get_bill_item,
    assign_bill_to_me,
    assign_bill,
    get_we_company_item,
    reset_bill,
    counteract_bill,
    delete_bill,
    edit_bill,
    reject_sub_bill,
    add_sub_bill,
    delete_sub_bill,
    close_bill_no_data,
    make_bill_main_bill,
    make_bill_normal_bill,
    make_bill_classcial_mode,
    change_bill_buline,
    refresh_billing,
    check_bill_done,
    get_bill_email_bundle,
    get_counteract_invoice_list,
    counteract_invoice,
    get_bill_link_against_bills,
    link_bill_against_bills,
    split_adjust_cost,
  } from '@/api/erp-bills';
  import { get_company_item } from '@/api/crm-customer';
  import { defineComponent, reactive, ref } from 'vue';
  import { useUser } from '@/hooks/useUser';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useBills } from './hooks/useBills';
  import { BasicTableProps, BasicColumn, ActionItem } from '@/components/WeTable';
  import { copyObjectToReactive, mapItems, isEmptyData, formatNumberByNumeral, findDialogInput } from '@/utils/common';
  import { formatMoneyByRound } from '@/utils/number';
  import { useUtils } from '@/hooks/useUtils';
  import { useInvoice } from '../invoices/hooks/useInvoice';
  import { appStore } from '@/store/modules/app';
  import { formatTime } from '@/utils/time';
  import { required } from '@/utils/validate';
  import { DialogFormItem } from '@/components/WeDialog/types';
  import Billing from '../components/Billing.vue';
  import EditEmail from '../components/EditEmail.vue';
  import EmailCards from '../components/EmailCards.vue';
  import GenerateBillPdf from './components/GenerateBillPdf.vue';
  import { isDev, isProd } from '@/utils/const';
  import { eq } from '@/utils/number';
  import { usePay } from '../pays/hooks/usePay';
  import { useJump } from '@/hooks/useJump';
  import { sum, pick } from 'lodash-es';

  export default defineComponent({
    name: 'ERPBillDetail',
    components: {
      Billing,
      EditEmail,
      EmailCards,
      GenerateBillPdf,
    },
    props: {
      billId: {
        type: [String, Number] as PropType<any>,
        default: undefined,
      },
    },
    setup() {
      const { editDemandPay, addBillPayment } = usePay();
      const {
        isAnalysis,
        user,
        isCapital,
        isAccountant,
        isTax,
        isFinance,
        isAdmin,
        isTeamHead,
        isGod,
        isOne,
        validUserList,
        hasPermission,
      } = useUser();
      const {
        bulineMap,
        currencyMap,
        weCompanyMap,
        userMap,
        model,
        teamMap,
        currencyList,
        getTfItems,
        teamList,
        bulineList,
        apiRoot,
        getTeamUserDialogChange,
        getMyTeamList,
        getTeamUsers,
        getGroupUsers,
      } = useAppInfo();
      const { jumpToContract, jumpToBill, jumpToInvoice, jumpToPay, jumpToCompany } = useJump();
      const { initBill, editBill, editBillForAdjust, editPlanPayTime, batchEditProductBilling } = useBills();
      const { editInvoice } = useInvoice();
      const { showJson } = useUtils();

      const billing = ref<Nullable<{ genPubCfm: Fn }>>(null);
      const BillSelectedRowKeys = ref<number[]>([]);
      const GenerateBillPdf = ref<any>(null);
      const editEmailValue = ref<any[]>([]);
      const bill = reactive<Recordable>({});
      const invoice = ref<Recordable>({});
      const preViewInfo = reactive<Recordable>({});

      return {
        hasPermission,
        jumpToPay,
        validUserList,
        isAnalysis,
        isTax,
        isGod,
        isOne,
        isAdmin,
        isTeamHead,
        isCapital,
        isAccountant,
        isFinance,
        user,
        bulineMap,
        currencyMap,
        weCompanyMap,
        userMap,
        model,
        teamMap,
        apiRoot,
        currencyList,
        bill,
        invoice,
        initBill,
        editBill,
        editBillForAdjust,
        editPlanPayTime,
        editInvoice,
        getTfItems,
        teamList,
        showJson,
        bulineList,
        jumpToBill,
        preViewInfo,
        jumpToCompany,
        jumpToContract,
        jumpToInvoice,
        batchEditProductBilling,
        getTeamUserDialogChange,
        getMyTeamList,
        getTeamUsers,
        getGroupUsers,
        editEmailValue,
        billing,
        BillSelectedRowKeys,
        editDemandPay,
        addBillPayment,
        GenerateBillPdf,
      };
    },
    data: () => ({
      isProd,
      linkRebateBillDialog: false,
      sendEmailLoading: false,
      billDetailLoading: false,
      readOnly: false,
      editorConfig: {
        plugins: 'lists',
        height: 300,
        branding: false,
        menubar: false,
        statusbar: false,
        toolbar: `undo redo | styleselect | fontselect | fontsizeselect | forecolor backcolor |
            bold italic underline strikethrough | bullist numlist | alignleft aligncenter alignright alignjustify`,
      },
      selectedFiles: [],
      linkRebateBillList: [],
      rebateBillList: [],
      expandBilling: false,
      subBillSplitType: 'Booking',

      linkRebateBillUnlinked: 0,
      linkRebateBillAllLinked: 0,
    }),
    computed: {
      isAutoBilling() {
        if (this.bill) {
          const tags = this.bill.billing ? this.bill.billing.billing_tags : [];
          return tags.length > 0;
        }
        return false;
      },

      contract() {
        if (this.bill) {
          return this.bill.contract!;
        } else {
          return null;
        }
      },
      linkPayList(): Recordable[] | null | undefined {
        return this.bill ? this.bill.link_pay_list : null;
      },
      subBillList(): Recordable[] | null | undefined {
        let list = this.bill ? this.bill.sub_bill_list : null;
        return list;
      },
      hedgeBillList(): Recordable[] | null | undefined {
        let list = this.bill && this.bill.hedge_bill_list ? this.bill.hedge_bill_list : [];
        return list;
      },
      isScalerLogic(): boolean {
        return this.contract && [this.model.Contract.LOGIC_TYPE.TAURUS].includes(this.contract.logic_type);
      },
      isCloudLogic(): boolean {
        return (
          this.contract &&
          [
            this.model.Contract.LOGIC_TYPE.CLOUD,
            this.model.Contract.LOGIC_TYPE.EE,
            this.model.Contract.LOGIC_TYPE.FEISHU,
          ].includes(this.contract.logic_type)
        );
      },
      showInvoice(): Boolean {
        return (
          !isEmptyData(this.bill) &&
          this.bill.is_demand &&
          this.weCompanyMap[this.bill.we_company_id!].region_type != this.model.WeCompany.REGION_TYPE.CHINA
        );
      },
      canSendInvoice(): boolean {
        const stateValid =
          this.bill &&
          this.bill.is_demand &&
          !this.bill.is_purchase &&
          !this.bill.is_sub_bill &&
          this.bill.state_value! > this.model.BillState.STATE.ACCOUNTANT_MAKING_INVOICE;
        const userValid = [this.bill.owner_id, this.bill.tracker_id].includes(this.user.id);
        const valid = stateValid && userValid && this.isCloudLogic;
        return (this.isGod ||
          isDev ||
          this.isFinance ||
          valid ||
          this.hasPermission('CanReviewEmailSendField')) as boolean;
      },
      subBillListConfig(): BasicTableProps {
        const config: BasicTableProps = {
          title: '子账单',
          useSearchForm: false,
          immediate: false,
          columns: [
            { title: 'Team', componentType: 'a-input', dataIndex: 'rd_team_name' },
            {
              title: '子账单名称',
              componentType: 'a-input',
              dataIndex: 'name',
              slots: { customRender: 'name' },
            },
            {
              title: '跟进人',
              componentType: 'a-input',
              dataIndex: 'rd_owner_name',
              slots: { customRender: 'rd_owner_name' },
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              componentType: 'a-input',
              slots: { customRender: 'rd_state_name' },
            },
            { title: 'POD2', dataIndex: 'pod2', componentType: 'a-input' },
            { title: '暂估金额', dataIndex: 'estimate_money', componentType: 'we-number-range' },
            { title: '发票金额', dataIndex: 'invoice_money', componentType: 'we-number-range' },
            { title: '税额', dataIndex: 'tax_money', componentType: 'we-number-range' },
            { title: '差值', dataIndex: 'cut_money', componentType: 'we-number-range' },
          ],
        };
        if (this.bill.is_media_mlbx) {
          (config.columns as BasicColumn[]).push({ title: 'POD', dataIndex: 'pod' });
        }
        if (this.canActionSubBill) {
          config.actionColumn = {
            title: '操作',
            dataIndex: 'action',
            slots: { customRender: 'action' },
          };
        }
        return config;
      },
      hedgeBillListConfig(): BasicTableProps {
        const config: BasicTableProps = {
          title: '红冲',
          useSearchForm: false,
          immediate: false,
          columns: [
            { title: 'Team', componentType: 'a-input', dataIndex: 'rd_team_name' },
            { title: '账单名称', componentType: 'a-input', dataIndex: 'name', slots: { customRender: 'name' } },
            { title: '类别', componentType: 'a-input', dataIndex: 'type', slots: { customRender: 'type' } },
            { title: '跟进人', componentType: 'a-input', dataIndex: 'rd_owner_name' },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              componentType: 'a-input',
              slots: { customRender: 'rd_state_name' },
            },
            { title: '暂估金额', dataIndex: 'estimate_money', componentType: 'we-number-range' },
            { title: '发票金额', dataIndex: 'invoice_money', componentType: 'we-number-range' },
            { title: '税额', dataIndex: 'tax_money', componentType: 'we-number-range' },
            { title: '差值', dataIndex: 'cut_money', componentType: 'we-number-range' },
          ],
        };
        return config;
      },
      linkPayListConfig(): BasicTableProps {
        if (this.isSupply) {
          return {
            title: '收付款',
            immediate: false,
            useSearchForm: false,
            columns: [
              {
                title: '名称',
                dataIndex: 'name',
                slots: { customRender: 'name' },
              },
              {
                title: '状态',
                dataIndex: 'rd_state_name',
                colorField: 'rd_state_color',
                slots: { customRender: 'rd_state_name' },
              },
              { title: '类型', dataIndex: 'rd_category' },
              { title: '计划付款日期', dataIndex: 'rd_plan_pay_time' },
              { title: '实际付款日期', dataIndex: 'rd_pay_time' },
              {
                title: '付款金额',
                dataIndex: 'pay_money',
                round: 2,
                hiddenFilter: true,
                componentType: 'we-number-range',
                numberFormat: '0,0.00',
              },
              {
                title: '核销金额',
                dataIndex: 'link_money',
                round: 2,
                hiddenFilter: true,
                componentType: 'we-number-range',
                numberFormat: '0,0.00',
              },
              { title: '创建时间', dataIndex: 'create_time' },
            ],
          };
        } else {
          return {
            title: '收付款',
            immediate: false,
            useSearchForm: false,
            columns: [
              {
                title: '名称',
                dataIndex: 'name',
                slots: { customRender: 'name' },
              },
              {
                title: '状态',
                dataIndex: 'rd_state_name',
                colorField: 'rd_state_color',
                slots: { customRender: 'rd_state_name' },
              },
              { title: '类型', dataIndex: 'rd_category' },
              { title: '收款日期', dataIndex: 'rd_pay_time' },
              { title: '收款金额', dataIndex: 'pay_money', round: 2 },
              { title: '核销金额', dataIndex: 'link_money', round: 2 },
              { title: '创建时间', dataIndex: 'create_time' },
            ],
          };
        }
      },
      linkInvoiceListConfig(): BasicTableProps {
        return {
          title: '发票',
          useSearchForm: false,
          useFilterFromColumns: false,
          immediate: false,
          columns: [
            {
              title: '名称',
              dataIndex: 'name',
              slots: {
                customRender: 'name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'color',
              slots: {
                customRender: 'rd_state_name',
              },
              componentType: 'a-input',
              showTop: true,
            },
            { title: '分类', dataIndex: 'category', componentType: 'a-input' },
            { title: '发票号码', dataIndex: 'invoice_number' },
            { title: '发票金额', dataIndex: 'it_amount', numberFormat: '0,0.00', componentType: 'we-number-range' },
            {
              title: '所有关联金额',
              dataIndex: 'linked_amount',
              numberFormat: '0,0.00',
              componentType: 'we-number-range',
            },
            {
              title: '当前关联金额',
              dataIndex: 'this_linked',
              numberFormat: '0,0.00',
              componentType: 'we-number-range',
            },
            { title: '创建时间', dataIndex: 'create_time' },
          ],
          initItem: (item) => {
            const state = this.model.InvoiceState.STATE;
            item.category = item.is_blank ? 'Virtual' : item.is_special ? 'Special' : 'Normal';
            item.type = item.is_purchase ? 'Purchase' : this.model.Invoice.TYPE[item.invoice_type];
            item.pathSuffix = item.type.toLowerCase();
            item.color =
              item.rd_state_value === state.DONE ? 'green' : item.rd_state_value === state.CLOSED ? 'grey' : 'orange';
          },
        };
      },
      rebateBillListConfig(): BasicTableProps {
        const config: BasicTableProps = {
          title: '返点抵扣',
          useSearchForm: false,
          immediate: false,
          columns: [
            {
              title: '账单名称',
              componentType: 'a-input',
              dataIndex: 'bill_name',
              slots: {
                customRender: 'bill_name',
              },
            },
            {
              title: '状态',
              dataIndex: 'bill_rd_state_name',
              colorField: 'bill_rd_state_color',
              componentType: 'a-input',
              slots: {
                customRender: 'bill_rd_state_name',
              },
            },
            { title: '暂估金额', dataIndex: 'bill_estimate_money', componentType: 'we-number-range' },
            { title: '发票金额', dataIndex: 'bill_invoice_money', componentType: 'we-number-range' },
            { title: '返点抵扣金额', dataIndex: 'link_money', componentType: 'we-number-range' },
          ],
        };
        return config;
      },
      linkRebateBillListColumns(): BasicColumn[] {
        const ret: BasicColumn[] = [
          {
            title: '账单名称',
            key: 'bill_name',
            dataIndex: 'bill_name',
            slots: { customRender: 'name' },
          },
          {
            title: 'To Pay Money',
            key: 'bill_to_pay_money',
            dataIndex: 'bill_to_pay_money',
            customRender: (text) => {
              return formatMoneyByRound(text.text);
            },
          },
          {
            title: 'Link Part',
            key: 'link_money',
            dataIndex: 'link_money',
            slots: {
              customRender: 'link_money',
            },
          },
        ];
        return ret;
      },
      showBill(): boolean {
        return !!this.bill && !this.contract.is_deleted;
      },
      isSupply(): boolean {
        return this.bill && this.bill.bill_type === this.model.Bill.TYPE.SUPPLY;
      },
      canAssign(): any {
        return (
          this.bill.owner_id === this.user.id ||
          this.bill.contract_owner_id === this.user.id ||
          this.isTeamHead(this.bill.team_id) ||
          this.isAdmin ||
          this.isAnalysis
        );
      },

      isMyBill(): boolean {
        return this.bill && this.bill.owner_id === this.user.id;
      },
      isMyState(): boolean {
        return this.bill && this.bill.flow! && this.user.id === this.bill.flow!.current_state.owner_id;
      },
      canBilling(): boolean {
        const isChecking =
          this.bill.state_value === this.model.BillState.STATE.SUB_AM_MAKING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.AM_CHECKING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.MAIN_AM_WAITING_BILL ||
          this.bill.state_value === this.model.BillState.STATE.MAIN_AM_GOT_BILL;
        return this.isAdmin || this.isAnalysis || (this.isMyState && isChecking);
      },
      canBillingSubBill(): boolean {
        return (
          this.bill.is_parent_bill! &&
          !this.bill.billing!.billing_tags.includes('StrictSystemBilling') &&
          this.canBilling
        );
      },
      canActionSubBill(): boolean {
        return (
          this.canBillingSubBill ||
          this.bill.billing!.billing_tags.includes('Cash') ||
          (this.bill.billing!.billing_tags.includes('MSBilling') &&
            (this.bill.billing!.billing_tags.includes('ML') || this.bill.billing!.billing_tags.includes('MSCustomer')))
        );
      },

      canCounteractInvoice(): boolean {
        return (
          (this.isTax || this.isGod || isDev) && this.model.BillState.STATE.CLOSED_NO_DATA === this.bill.state_value
          // &&(this.bill.invoice_linked_list!.length === 0 || !eq(this.bill.finance_invoice_money || 0, this.bill.invoice_linked_money || 0))
        );
      },
      canCloseNoData(): boolean {
        return (
          (this.isAnalysis || this.isAdmin) &&
          !this.bill.is_purchase &&
          !this.bill.estimate_money &&
          !this.bill.invocie_money &&
          ![this.model.BillState.STATE.CLOSED, this.model.BillState.STATE.CLOSED_NO_DATA].includes(
            this.bill.state_value
          )
        );
      },

      canReset(): boolean {
        return (
          isEmptyData(this.linkPayList) &&
          !this.bill.is_purchase &&
          (this.bill.is_normal_bill! || this.bill.is_parent_bill!) &&
          !this.bill.hedged_bill_id &&
          ![this.model.BillState.STATE.GETTING_MONEY, this.model.BillState.STATE.GIVING_MONEY].includes(
            this.bill.state_value
          ) &&
          (this.isTeamHead(this.bill.team_id) || this.isAdmin || this.isFinance)
        );
      },
      canCounteractBill(): boolean {
        return (
          isEmptyData(this.linkPayList) &&
          !this.bill.is_purchase &&
          (this.bill.is_normal_bill! || this.bill.is_parent_bill!) &&
          !this.bill.hedged_bill_id &&
          [this.model.BillState.STATE.GETTING_MONEY, this.model.BillState.STATE.GIVING_MONEY].includes(
            this.bill.state_value
          ) &&
          (this.isTeamHead(this.bill.team_id) || this.isMyBill || this.isAdmin || this.isFinance)
        );
      },
      canAssignToMe(): Boolean {
        let res = false;
        if (this.bill.flow_id) {
          const state = this.bill.flow!.current_state;
          const ROLE = this.model.FlowState.ROLE;
          if (state.owner.id !== this.user.id) {
            if (this.isAnalysis && state.role === ROLE.FBP) {
              res = true;
            }
            if (this.isCapital && state.role === ROLE.TREASURY) {
              res = true;
            }
            if (this.isAccountant && state.role === ROLE.ACCT) {
              res = true;
            }
            if (this.isTax && state.role === ROLE.TAX) {
              res = true;
            }
            if (
              this.bill.related_bill_id &&
              (this.isAccountant || this.isTax) &&
              state.value === this.model.BillState.STATE.ACCOUNTANT_REVIEWING
            ) {
              res = true;
            }
          }
        }

        return res;
      },
      canDoAdjustBilling(): Boolean {
        return !isEmptyData(this.bill) && this.bill.logic_detail.includes('Adjust_Split') && this.canBilling;
      },
      get_drop_down_menus(): Recordable[] {
        const vm = this;
        if (!vm.bill) {
          return [];
        } else if (this.hasPermission('RoleFinanceDataViewer') && !this.isGod) {
          return [{ show: true, label: '修改记录', onClick: () => vm.$showChange(vm.bill, 'bill') }];
        }

        const ret = [
          { show: this.canAssign, label: '分配', onClick: () => this.assignBill(this.bill) },
          { show: this.canAssignToMe, label: '认领', onClick: this.assignToMe },
          { show: this.canSendInvoice, label: '发送邮件', onClick: () => this.openSendInvoiceEmailDialog() },

          { show: vm.isOne && vm.bill.is_parent_bill, label: 'Make N', onClick: () => vm.makeBillN(vm.bill) },
          { show: vm.isOne && vm.bill.is_normal_bill, label: 'Make M', onClick: () => vm.makeBillM(vm.bill) },
          {
            show:
              (vm.isOne ||
                ((vm.hasPermission('CanForceManualBilling') || vm.isMyState || vm.isAdmin) && vm.canBilling)) &&
              !vm.bill.is_force_manual_mode,
            label: '强制手动模式',
            onClick: () => vm.makeBillManualMode(vm.bill),
          },
          { show: this.canBillingSubBill, label: '新增子账单', onClick: this.addSubBill },
          {
            show: vm.isAdmin || vm.isFinance,
            label: this.bill.is_demand ? '编辑完成时间' : '编辑发票日期',
            onClick: () => this.checkBillDone(this.bill),
          },
          {
            show: (vm.isAdmin || vm.isAnalysis) && !vm.bill.is_purchase,
            label: vm.bill.is_supply ? '修改计划付款时间' : '修改计划收款时间',
            onClick: () => vm.editPlanPayTime(vm, vm.bill),
          },
          { show: vm.canCounteractInvoice, label: '发票抵消', onClick: () => vm.counteractInvoice(vm.bill) },
          { show: vm.canCounteractBill, label: '账单红冲', onClick: () => vm.counteractBill() },
          { show: vm.canReset, label: '重置', onClick: this.resetBill },
          {
            show: (vm.isFinance || vm.isAdmin) && !vm.bill.is_purchase,
            label: '刷新Billing',
            onClick: () => vm.refreshGenBill(),
          },
          {
            show: vm.canCloseNoData && !vm.bill.invoice_linked_amount && !vm.bill.is_purchase,
            label: '关闭无数据账单',
            onClick: () => vm.closeNoData(vm.bill),
          },
          {
            show: (vm.isAdmin || vm.isFinance) && !vm.bill.is_parent_bill && !vm.bill.is_purchase,
            label: '编辑业务线',
            onClick: () => vm.setBuline(),
          },
          {
            show: (vm.isAdmin || vm.isAnalysis || isDev) && !vm.bill.is_purchase && vm.bill.parent_id === 0,
            label: '设置坏账',
            onClick: () => vm.setBadBill(),
          },
          {
            show: vm.canDoAdjustBilling,
            label: 'Adjust对账',
            onClick: () => vm.adjustBilling(),
          },
          {
            show: vm.isAdmin && !vm.bill.is_sub_bill && !vm.bill.is_purchase,
            label: '删除',
            onClick: () => vm.deleteBill(),
          },
          {
            show: vm.isAdmin || vm.isFinance || isDev,
            label: '修改记录',
            onClick: () => vm.$showChange(vm.bill, 'bill'),
          },
          { show: vm.isGod || isDev, label: 'Json', onClick: () => vm.showJson(vm.bill) },
          { show: vm.isGod || isDev, label: '跳转HW3', onClick: () => vm.jumpToHW3() },
          { show: vm.isScalerLogic, label: '同步合同税率', onClick: () => vm.syncScalerTaxRate() },
        ];

        return ret.filter((x) => x.show);
      },
      canEmail(): boolean {
        let valid = this.bill && this.bill.is_demand && !this.bill.is_sub_bill;
        // valid = valid && this.bill.state_value! > this.model.BillState.STATE.ACCOUNTANT_MAKING_INVOICE;
        valid = valid && (this.isCloudLogic || this.isScalerLogic);
        return (
          (this.bill.owner_id === this.user.id ||
            this.isGod ||
            this.isFinance ||
            this.hasPermission('RoleCloudBillingAdmin')) &&
          valid
        );
      },
      canLinkRebate(): boolean {
        const STATE = this.model.BillState.STATE;
        const isRebate = this.bill.is_rebate!;
        const isMyState = this.user.id === this.bill.flow!.current_state.owner_id;
        const isCheckingState = [STATE.AM_CHECKING_BILL, STATE.MAIN_AM_GOT_BILL, STATE.MAIN_AM_WAITING_BILL].includes(
          this.bill.state_value
        );
        return isRebate && ((isMyState && isCheckingState) || this.isFinance || this.isAdmin);
      },
    },
    created() {
      if (!this.billId) {
        this.$router.go(-1);
        return;
      }
      this.getBillDetail(this.billId);
    },
    methods: {
      eq,
      isEmptyData,
      formatNumberByNumeral,
      formatMoneyByRound,
      async getBillDetail(id) {
        this.billDetailLoading = true;
        await get_bill_item({ bill_id: Number(id) }).then((res) => {
          this.initBill(this, res.bill);
          copyObjectToReactive(this.bill, res.bill);
          this.billDetailLoading = false;
          document.title = this.bill.name!;
        });
        get_bill_link_against_bills({ bill_id: id }).then((res) => {
          copyObjectToReactive(this.rebateBillList, res);
        });
      },
      jumpToHW3() {
        const url = `${window.location.origin}/business/hw3/bill/detail/${this.bill.id}`;
        window.open(url, '_blank');
      },
      initData() {
        this.getBillDetail(this.billId);
      },
      doAfterEditBill(res) {
        appStore.SET_WE_DIALOG(null);
        appStore.SET_ALERT_CONFIG(null);
        if (res === 'delete') {
          this.bill = {};
          appStore.SET_DRAWER_CONFIG(null);
          this.$router.go(-1);
          // 返回列表页
        } else if (this.bill) {
          this.initData();
        }
        if (res.pay) {
          this.jumpToPay(res.pay);
        }
      },
      afterEditBill(res) {
        this.doAfterEditBill(res);
      },
      assignToMe() {
        const originUser = this.bill.flow!.current_state.owner.name;
        const tip = `Are you sure to assign this bill from ${originUser} to yourself?`;
        const params = { bill_id: this.bill.id };
        const callback = () => {
          assign_bill_to_me(params).then((res) => {
            this.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        };

        this.$showAlert('Warning', tip, callback);
      },

      assignBill(bill) {
        const vm = this;
        const teamItems = mapItems(this.getMyTeamList(true));
        const ownerItems = mapItems(this.getTeamUsers(bill.team_id));
        const trackerItems = mapItems(this.getGroupUsers(bill.team_id));
        const assignBlock: DialogFormItem = {
          type: 'input',
          value: [
            {
              type: 'select',
              label: '所在团队',
              result: 'team_id',
              width: 24,
              rules: [
                {
                  required: true,
                  trigger: 'change',
                  type: 'number',
                },
              ],
              items: teamItems,
              change: this.getTeamUserDialogChange('team_id', 'owner_id'),
            },
            {
              type: 'select',
              label: '跟进人',
              result: 'owner_id',
              rules: [
                {
                  required: true,
                  trigger: 'change',
                  type: 'number',
                },
              ],
              width: 24,
              items: ownerItems,
            },
            {
              type: 'select',
              label: '代跟人',
              result: 'tracker_id',
              width: 24,
              items: trackerItems,
            },
          ],
        };
        vm.$showDialog({
          width: '400px',
          title: '分配账单',
          labelCol: '100px',
          blocks: [assignBlock],
          init: bill,
          callback: (result) => {
            const params = {
              id: bill.id,
              ...result,
            };
            assign_bill(params).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      async catchFlowFlag(otherOper) {
        const model = this.model;
        if (otherOper === 'FLAG_EDIT_BILL_MONEY') {
          this.editBill(this, this.bill);
        } else if (otherOper === 'FLAG_EDIT_BILL_MONEY_SPLITED') {
          this.editBill(this, this.bill);
        } else if (otherOper === 'FLAG_EDIT_INVOICE') {
          let bill = this.bill;
          if (this.bill.related_bill_id && this.bill.finance_type === model.Invoice.TYPE.SUPPLY) {
            bill = this.bill.related_bill;
          }
          const pageConfig =
            bill.finance_type === model.Invoice.TYPE.SUPPLY
              ? {
                  pathSuffix: 'supply',
                  title: 'Supply 发票',
                  customer: '销售方',
                  customerCategory: 'Supply',
                  type: model.Invoice.TYPE.SUPPLY,
                }
              : {
                  pathSuffix: 'demand',
                  title: 'Demand 发票',
                  customer: '客户',
                  customerCategory: 'Demand',
                  type: model.Invoice.TYPE.DEMAND,
                };

          const initInvoice: Recordable = {
            we_company_id: bill.contract?.we_company_id,
            contract: bill.contract,
            company_id: bill.contract?.company_id,
            currency_id: bill.currency_id,
            is_china:
              this.weCompanyMap[this.bill.we_company_id!].region_type === this.model.WeCompany.REGION_TYPE.CHINA,
            date: formatTime(Date(), 'YYYY-MM-DD'),
          };
          get_we_company_item({ we_company_id: bill.contract?.we_company_id }).then((res) => {
            initInvoice.we_tax_number = res.tax_number;
            initInvoice.we_address = res.address;
            initInvoice.we_phone = res.phone;
          });
          get_company_item({ company_id: bill.contract!.company_id }).then((res) => {
            initInvoice.is_rebate = bill.is_rebate;
            initInvoice.it_amount = bill.finance_invoice_money;
            initInvoice.cs_tax_number = res.company.tax_number;
            initInvoice.cs_address = res.company.invoice_address;
            initInvoice.cs_phone = res.company.invoice_phone;
            initInvoice.tax_rate = Number(((bill.tax_rate || 0) * 100).toFixed(2));
            if (this.bill.bill_type === this.model.Bill.TYPE.DEMAND) {
              initInvoice.is_special = initInvoice.is_china;
            }
            this.editInvoice(this, 'add', pageConfig, initInvoice, [bill.id]);
          });
        } else if (otherOper === 'FLAG_GEN_PUB_CONFIRMATION_AND_REVIEW') {
          this.billing!.genPubCfm();
        } else if (otherOper === 'FLAG_ADD_SUPPLY_PAY') {
          this.addBillPayment(this.bill, this);
        } else if (otherOper === 'FLAG_ADD_DEMAND_PAY') {
          const item = {
            company_id: this.bill.company_id,
            we_company_id: this.bill.we_company_id,
            currency_id: this.bill.currency_id,
            pay_money: this.bill.finance_to_pay_money,
            category: 0,
          };
          if (this.bill.is_prepay) {
            item.category = this.model.Pay.CATEGORY.PREPAY;
          } else {
            item.category = this.model.Pay.CATEGORY.NORMAL;
          }
          this.editDemandPay('add', item, this, this.bill.id);
        } else if (otherOper === 'FLAG_GEN_SCALER_BILLING') {
          (this.$refs.GenerateBillPdfRef as any).show_generate_bill_dialog();
        } else if (otherOper === 'FLAG_SEND_SCALER_BILLING') {
          this.openSendInvoiceEmailDialog();
        } else if (otherOper === 'FLAG_GO_BACK_MAIN_WAITING_BILL') {
          this.resetSubBill();
        }
      },
      resetSubBill() {
        const inputs = [
          {
            label: '子账单',
            result: 'sub_bills',
            type: 'check',
            width: 24,
            value: this.bill.sub_bill_list.map((m) => ({ value: m.id, label: m.name })),
          },
        ];
        const dialog = {
          title: '请选择需要重新对账的子账单',
          blocks: [{ type: 'input', value: inputs }],
          labelCol: '60px',
          width: '800px',
          callback: (result) => {
            reject_sub_bill({ sub_bill_ids: result.sub_bills }).then((res) => {
              appStore.SET_WE_DIALOG(null);
              this.afterEditBill(res);
            });
          },
        };
        this.$showDialog(dialog);
      },
      resetBill() {
        const vm = this;
        vm.$showAlert(
          'Warning',
          'Are you sure to reset this bill? All steps will be canceled and reset to "AM Making Bill"',
          () => {
            reset_bill({ bill_id: vm.bill.id }).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_ALERT_CONFIG(null);
            });
          }
        );
      },
      deleteBill() {
        const vm = this;
        vm.$showAlert('Warning', 'Are you sure to DELETE this bill?', () => {
          delete_bill({ bill_id: vm.bill.id }).then((res) => {
            if (res.status === 500) {
              vm.$showAlert('Error', res.error);
              return;
            }
            vm.afterEditBill('delete');
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      setBadBill() {
        const vm = this;
        this.$showDialog({
          title: '设置坏账',
          width: '400px',
          labelCol: '120px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'radio',
                  label: '是否坏账',
                  result: 'is_bad_bill',
                  items: vm.getTfItems,
                  width: 24,
                },
              ],
            },
          ],
          init: vm.bill,
          callback: (result) => {
            console.log();
            edit_bill({ id: vm.bill.id, ...result }).then((res) => {
              appStore.SET_WE_DIALOG(null);
              vm.afterEditBill(res);
            });
          },
        });
      },
      rejectSubBill(bill) {
        const vm = this;
        vm.$showAlert('Warning', 'Are you sure to Reject this sub-bill?', () => {
          reject_sub_bill({ sub_bill_id: bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      addSubBill() {
        const vm = this;
        const teamItems = this.teamList
          .filter((x) => !x.is_history)
          .map((x) => ({ text: x.short_name, value: x.id, label: x.short_name }));
        const bulineItems = this.bulineList
          .filter((x) => !x.is_history)
          .map((x) => ({ text: x.name, value: x.id, label: x.name }));
        const userItems = this.validUserList.map((x) => ({
          text: x.name,
          value: x.id,
          label: x.name,
        }));
        console.log(vm.isGod);

        vm.$showDialog({
          title: '新增子账单',
          width: '400px',
          labelCol: '80px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'auto-select',
                  label: '团队',
                  result: 'team_id',
                  items: teamItems,
                  width: 24,
                  rules: vm.isGod ? [] : [required('number', '请选择Team') as any],
                },
                {
                  type: 'auto-select',
                  label: 'AM',
                  result: 'am_id',
                  items: userItems,
                  width: 24,
                  rules: vm.isGod ? [] : [required('number', '请选择AM') as any],
                },
                {
                  type: 'auto-select',
                  label: '业务线',
                  result: 'buline_id',
                  items: bulineItems,
                  width: 24,
                  rules: [required('number', '请选择Buline') as any],
                },
              ],
            },
          ],
          callback: (result) => {
            add_sub_bill({ bill_id: vm.bill.id, data: result }).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
        return;
      },
      deleteSubBill(sb) {
        const vm = this;
        if (vm.subBillList!.length === 1) {
          vm.$showAlert('Alert', 'CANNOT delete the last sub-bill.');
        } else {
          vm.$showAlert('Delete Sub-Bill', 'Are you sure to delete Sub-Bill of "' + sb.rd_buline_name + '"?', () => {
            delete_sub_bill({ sub_bill_id: sb.id }).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_ALERT_CONFIG(null);
            });
          });
        }
      },
      makeBillN(bill) {
        const vm = this;
        vm.$showAlert('Warning', '确定将账单调整为【普通账单(N)】?', () => {
          make_bill_normal_bill({ bill_id: bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      makeBillM(bill) {
        const vm = this;
        vm.$showAlert('Warning', '确定将账单调整为【主账单(M)】?', () => {
          make_bill_main_bill({ bill_id: bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      makeBillManualMode(bill) {
        const vm = this;
        vm.$showAlert('Warning', '确定将此账单修改为"手动对账模式"？注意，此操作【无法恢复】?', () => {
          make_bill_classcial_mode({ bill_id: bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      closeNoData(bill) {
        const vm = this;
        vm.$showAlert('Warning', '确定关闭无数据账单?', () => {
          close_bill_no_data({ bill_id: bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      syncScalerTaxRate() {
        const vm = this;
        sync_scaler_tax_rate({ bill_id: Number(vm.billId) }).then(() => {
          vm.$message.success('操作成功');
          vm.getBillDetail(vm.billId);
        });
      },
      async counteractInvoice(bill) {
        const vm = this;
        const invoiceList = await get_counteract_invoice_list({ bill_id: bill.id });
        const invoiceItems = invoiceList.map((x) => ({ text: x.name, value: x.id }));
        let invoice_ids_1_amount = 0;
        const inputs = [
          {
            type: 'select',
            label: '发票名称',
            result: 'invoice_ids_1',
            items: invoiceItems,
            mode: 'multiple',
            rules: [required()],
            change: (result, _input, config) => {
              invoice_ids_1_amount = sum(
                invoiceList.filter((x) => result.invoice_ids_1.includes(x.id)).map((x) => x.it_amount)
              );
              const entityInput = findDialogInput(config, 'invoice_ids_2');
              if (!isEmptyData(result.invoice_ids_1) && !isEmptyData(result.invoice_ids_2)) {
                const invoice_ids_2 = result.invoice_ids_2.filter((x) => !result.invoice_ids_1.includes(x));
                if (invoice_ids_2.length !== result.invoice_ids_2.length) {
                  result.invoice_ids_2 = invoice_ids_2;
                }
              }
              entityInput.items = invoiceList
                .filter((x) => !(result.invoice_ids_1 || []).includes(x.id))
                .map((x) => ({ text: x.name, value: x.id }));
            },
          },
          {
            type: 'select',
            label: '抵消发票',
            result: 'invoice_ids_2',
            items: invoiceItems,
            mode: 'multiple',
            rules: [
              required(),
              {
                validator: async (_rule, val: any) => {
                  if (
                    val.length === 0 ||
                    !eq(
                      sum(invoiceList.filter((x) => val.includes(x.id)).map((x) => x.it_amount)) + invoice_ids_1_amount,
                      0
                    )
                  ) {
                    return Promise.reject('抵消金额不正确，请检查！');
                  } else {
                    return Promise.resolve();
                  }
                },
                trigger: 'change',
              },
            ],
            change: (result, _input, config) => {
              const entityInput = findDialogInput(config, 'invoice_ids_1');
              if (!isEmptyData(result.invoice_ids_2) && !isEmptyData(result.invoice_ids_1)) {
                const invoice_ids_1 = result.invoice_ids_1.filter((x) => !result.invoice_ids_2.includes(x));
                if (invoice_ids_1.length !== result.invoice_ids_1.length) {
                  result.invoice_ids_1 = invoice_ids_1;
                }
              }
              entityInput.items = invoiceList
                .filter((x) => !(result.invoice_ids_2.includes(x.id) || []))
                .map((x) => ({ text: x.name, value: x.id }));
            },
          },
        ];
        vm.$showDialog({
          width: '600px',
          title: '发票抵消',
          labelCol: '100px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            const params = { bill_id: bill.id, invoice_ids: [...result.invoice_ids_2, ...result.invoice_ids_1] };
            counteract_invoice(params).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      counteractBill() {
        const vm = this;
        vm.$showAlert('操作警告', '确定红冲账单并生成蓝色账单？注意：【此操作不可恢复，请与财务确认！！！】', () => {
          counteract_bill({ bill_id: vm.bill.id }).then((res) => {
            vm.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      setBuline() {
        const vm = this;
        const radioList = vm.teamMap[vm.bill.team_id!].buline_list.map((l) => ({
          text: l.name,
          value: l.id,
        }));

        vm.$showDialog({
          title: '编辑业务线',
          labelCol: '80px',
          width: '400px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  label: '业务线',
                  rules: [required('number', '请选择业务线') as any],
                  type: 'select',
                  items: radioList,
                  result: 'buline_id',
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            change_bill_buline({ bill_id: vm.bill.id, ...result }).then((res) => {
              vm.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      openSendInvoiceEmailDialog() {
        get_bill_email_bundle({ bill_id: this.bill.id }).then((res: any) => {
          if (res.error_message) {
            this.$showAlert('Error', res.error_message);
          } else {
            this.editEmailValue = res;
          }
        });
      },
      refreshGenBill() {
        this.$showAlert('Warning', 'Are you sure to Refresh Billing?', () => {
          refresh_billing({ bill_id: this.bill.id }).then((res) => {
            this.afterEditBill(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      checkBillDone(bill) {
        let title = this.bill.is_demand ? '对账结束日期' : '获取发票日期';
        this.$showDialog({
          title: title,
          width: '600px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'date',
                  result: 'invoice_date',
                  label: title,
                  rules: [required('string', '请选择日期')],
                  init: bill.check_bill_finish_time || formatTime(new Date(), 'YYYY-MM-DD'),
                },
              ],
            },
          ],
          callback: (result) => {
            check_bill_done({ bill_id: this.bill.id, ...result }).then((res) => {
              this.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      handleSubBillsActions(record: any, _column: BasicColumn): ActionItem[] {
        const actionItem: ActionItem[] = [];
        if (
          this.isGod ||
          this.isAdmin ||
          this.isFinance ||
          [this.bill.owner_id, this.bill.contract_owner_id].includes(this.user.id)
        ) {
          if (record.billing_type === this.model.Bill.BILLING_TYPE.NORMAL) {
            actionItem.push({
              label: '删除',
              color: 'error',
              onClick: () => this.deleteSubBill(record),
            });

            if (record.collect_done) {
              actionItem.push({
                label: '拒绝',
                onClick: () => this.rejectSubBill(record),
              });
            } else {
              actionItem.push({
                label: '编辑',
                onClick: () => this.editBill(this, record, true),
              });
            }
          } else {
            actionItem.push({
              label: '编辑',
              onClick: () => this.editBillForAdjust(this, record),
            });
          }
        }

        return actionItem;
      },
      async openLinkRebateBillDialog() {
        this.linkRebateBillUnlinked = (this.bill.to_pay_money || 0) - (this.bill.linked_money || 0);
        await get_bill_link_against_bills({ bill_id: this.bill.id, with_to_link: true }).then((res) => {
          this.linkRebateBillList = res.filter((rb) => rb.id || !eq(rb.to_pay_money, 0));
          this.BillSelectedRowKeys = [];
          this.linkRebateBillList.forEach((rb: any, index) => {
            if (rb.id) {
              rb.is_checked = true;
              this.BillSelectedRowKeys.push(index);
            }
            rb.link_money_copy = rb.link_money;
          });
          this.linkRebateBillDialog = true;
        });
      },
      linkBillCheckAllChange(val) {
        this.BillSelectedRowKeys = val;
        if (this.BillSelectedRowKeys.length === 0) {
          this.linkRebateBillList.forEach((rb: any) => {
            rb.is_checked = false;
            rb.link_money = 0;
            this.linkRebateBillUnlinked = this.linkRebateBillUnlinked + (rb.link_money_copy || 0);
            rb.link_money_copy = rb.link_money;
          });
        } else {
          this.linkRebateBillList.forEach((rb: any, index) => {
            if (this.BillSelectedRowKeys.includes(index)) {
              rb.is_checked = true;
            }
          });
        }
      },
      linkBillChange(bill, selected) {
        if (!selected) {
          bill.is_checked = false;
          bill.link_money = 0;
          this.linkRebateBillUnlinked = this.linkRebateBillUnlinked + (bill.link_money_copy || 0);
          bill.link_money_copy = bill.link_money;
        } else {
          bill.is_checked = true;
          bill.link_money = bill.link_money || 0;
        }
      },
      linkMoneyChanged(bill) {
        bill.link_money = bill.link_money || 0;
        this.linkRebateBillUnlinked =
          this.linkRebateBillUnlinked + (bill.link_money_copy || 0) - (bill.link_money || 0);
        bill.link_money_copy = bill.link_money;
      },
      linkRebateBill() {
        const pay_link_list = this.linkRebateBillList
          .filter((b: any) => b.is_checked)
          .map((b: any) => pick(b, ['id', 'bill_id', 'link_money']));
        link_bill_against_bills({ bill_id: this.bill.id, pay_link_list: pay_link_list }).then((res) => {
          this.linkRebateBillDialog = false;
          this.afterEditBill(res);
        });
      },
      adjustBilling() {
        this.$showDialog({
          title: 'Adjust Billing',
          labelCol: '80px',
          width: '600px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'number',
                  result: 'all_cost',
                  label: 'All Cost',
                  rules: [required()],
                },
              ],
            },
          ],
          callback: (result) => {
            split_adjust_cost({ bill_id: this.bill.id, ...result }).then((res) => {
              this.afterEditBill(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
    },
  });
